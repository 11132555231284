import { useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import Floc from '../pages/rcm/Floc'
import RcmAnalysis from '../pages/rcm/RcmAnalysis'
import RcmConditionHistory from '../pages/rcm/RcmConditionHistory'
import RcmCustomReports from '../pages/rcm/RcmCustomReports'
import RcmMaintenance from '../pages/rcm/RcmMaintenance'
import RcmSystems from '../pages/rcm/Systems'
import RcmDashboard from '../pages/rcm/RcmDashboard'
import FlocTag from '../pages/rcm/FLocTag'
import RcmFailureModes from '../pages/rcm/RcmFailureModes'
import RcmFMEA from '../pages/rcm/rcmFMEA'
import RcmDecissionTree from '../pages/rcm/RcmDescissionTree'
import RcmEquipment from '../pages/rcm/RcmEquipment'
import RcmFunctionFailure from '../pages/rcm/RcmFunctionFailure'
import RcmSystemDetail from '../pages/rcm/RcmSystemDetail'
import RcmSerivces from '../pages/RcmServices'
import RcmKpi from '../pages/rcm/RcmKpi'
import DynamicKeyDrawings from '../pages/rcm/RcmDynamicDrawings'

const rcmData = [
  {
    'S:No': '1',
    Number: 'RCM-SYS12-002',
    Description: 'Review Criticality and MEI Value',
    Status: 'Approved',
    System: '12',
    Criticality: 'SECE'
  },
  {
    'S:No': '2',
    Number: 'RCM-SYS43-003',
    Description: 'Review failure modes and Criticality',
    Status: 'Approved',
    System: '43',
    Criticality: 'HIGH'
  },
  {
    'S:No': '3',
    Number: 'RCM-SYS13-004',
    Description: 'Review Criticality and MEI Value',
    Status: 'Approved',
    System: '13',
    Criticality: 'HIGH'
  },
  {
    'S:No': '4',
    Number: 'RCM-SYS44-007',
    Description: 'Task review and update risk assesment',
    Status: 'Approved',
    System: '44',
    Criticality: 'HIGH'
  },
  {
    'S:No': '5',
    Number: 'RCM-SYS45-009',
    Description: 'Review failure modes and Criticality',
    Status: 'Approved',
    System: '47',
    Criticality: 'HIGH'
  }
]

export default function RcmRoutes() {
  const [pin, setPin]: any = useState()
  return (
    <Routes>
      <Route path="/" element={<RcmSerivces />} />
      <Route path="/dashboard" element={<RcmDashboard />} />
      <Route
        path="/sytems"
        element={<RcmSystems pin={pin} setPin={setPin} />}
      />
      <Route path="/sytems/:id" element={<RcmSystemDetail />} />
      <Route path="/floc" element={<Floc pin={pin} setPin={setPin} />} />
      <Route
        path="/functionFailure"
        element={<RcmFunctionFailure pin={pin} setPin={setPin} />}
      />
      <Route path="/equipment" element={<RcmEquipment />} />
      <Route
        path="/analysis"
        element={<RcmAnalysis pin={pin} setPin={setPin} />}
      />
      <Route path="/maintenanceLibrary" element={<RcmMaintenance />} />
      <Route path="/conditionHistory" element={<RcmConditionHistory />} />
      <Route path="/customReports" element={<RcmCustomReports />} />
      <Route
        path="/failureModes"
        element={<RcmFailureModes pin={pin} setPin={setPin} />}
      />
      <Route path="/fmea" element={<RcmFMEA pin={pin} setPin={setPin} />} />
      <Route
        path="/decissionChart"
        element={<RcmDecissionTree pin={pin} setPin={setPin} />}
      />
      <Route path="/kpi" element={<RcmKpi />} />
      <Route
        path="/dynamicDrawings"
        element={<DynamicKeyDrawings onBack={'/cmms/rcm'} rcmData={rcmData} />}
      />
      <Route path="/floc/:id" element={<FlocTag />} />
    </Routes>
  )
}
