import React from 'react'
import { Input } from 'reactstrap'
import { Text } from '../../components/RiskAssesmentPreview'
import './../../../src/components/css/tree.css'
import { treeRendering } from './RcmConsequenceAnalysis'

const treeData = [
  {
    id: '1',
    diamond: false,
    component: (
      <>
        <Text>Any applicable CD Task ?</Text>
        <Input type="checkbox" />
      </>
    ),
    children: [
      {
        id: '2',
        component: (
          <>
            <Text>
              <b>Yes</b>
            </Text>
            <Text>Specify CD Task</Text>
          </>
        ),
        diamond: false
      },
      {
        id: '7',
        component: (
          <>
            <Text>
              <b>No</b>
            </Text>
            <Text>Are there any applicable TD Tasks ?</Text>
            <Input type="checkbox" />
          </>
        ),
        diamond: false,
        children: [
          {
            id: '3',
            component: (
              <>
                <Text>
                  <b>Yes</b>
                </Text>
                <Text>Specify TD Task </Text>
              </>
            ),
            diamond: false
          },
          {
            id: '5',
            component: (
              <>
                <Text>
                  <b>No</b>
                </Text>
                <Text>Are there any combinations of Task Effective ?</Text>
                <Input type="checkbox" />
              </>
            ),
            diamond: false,
            children: [
              {
                id: '6',
                component: (
                  <>
                    <Text>
                      <b>Yes</b>
                    </Text>
                    <Text>Specify CD/TD/FF Task</Text>
                  </>
                ),
                diamond: false
              },
              {
                id: '4',
                component: (
                  <>
                    <Text>
                      <b>No</b>
                    </Text>
                    <Text>
                      Can Designing Modification Eliminate Failure mode of this
                      effect ?
                    </Text>
                    <Input type="checkbox" />
                  </>
                ),
                diamond: false,
                children: [
                  {
                    id: '8',
                    component: (
                      <>
                        <Text>
                          <b>Yes</b>
                        </Text>
                        <Text>Design Modification</Text>
                      </>
                    ),
                    diamond: false
                  },
                  {
                    id: '9',
                    component: (
                      <>
                        <Text>
                          <b>No</b>
                        </Text>
                        <Text>Accept the Failure Risk</Text>
                      </>
                    ),
                    diamond: false
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]

const RcmTaskSelection = () => {
  return <div className="tree">{treeRendering(treeData)}</div>
}

export default RcmTaskSelection
