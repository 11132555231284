import { Field, useForm } from "react-final-form";
import { Col } from "reactstrap";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

import CustomLabel from "./CustomLabel";

const textArea = {
  border: '1px solid #CCC',
  borderRadius: 5,
  width: '100%',
  height: 40,
  paddingLeft: 5,
};

type InputType = {
  label?: string,
  name?: any,
  isDisabled?: boolean,
  options?: any,
  isMulti?: boolean,
  occupy?: number
}

export default function MultiSelect({ label, name, isDisabled = false, options, isMulti = true, occupy = 6 }: InputType) {
  return (
    <Col md={occupy} key={`${name}*${label}`}>
      {label && <CustomLabel label={label} />}
      <Field name={name} style={textArea}>
        {({ input, meta }) => (
          <div key={`${name}^${label}`}>
            <CreatableSelect
              {...input}
              options={options}
              isMulti={isMulti}
            />
            {meta.error && meta.touched && (
              <span style={{ color: 'red', fontWeight: 400, fontSize: 14 }}>
                {meta.error}
              </span>
            )}
          </div>
        )}
      </Field>
    </Col>
  );
}