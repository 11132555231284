import FolderTree from 'react-folder-tree'
import { BsFileEarmarkTextFill } from 'react-icons/bs'
import { PiFolderNotchOpenFill, PiFolderSimpleFill } from 'react-icons/pi'
import { Button, Modal, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react'

import 'react-folder-tree/dist/style.css'

import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import SectionHeader from '../../components/Layout/SectionHeader'
import ModalTitle from '../../components/ModalTitle'
import { treeData } from './hierarchyData'
import { documents } from './documentsData'

export const renderData = (key: any, value: any) => (
  <div
    style={{
      marginBottom: 2,
      width: '100%',
      fontSize: 16
    }}
  >
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <p
        style={{
          width: '30%',
          margin: 0,
          fontSize: 14,
          fontWeight: 500
        }}
      >
        {key}
      </p>
      <p
        style={{
          margin: 0,
          fontSize: 14
        }}
      >
        {value}
      </p>
    </div>
  </div>
)

export const Link = ({ children, onClick = {} }: any) => {
  return (
    <>
      <a
        style={{
          color: 'blue',
          cursor: 'pointer',
          textDecoration: 'underline'
        }}
        onClick={onClick}
      >
        {children}
      </a>
      <br />
    </>
  )
}

export const Box = ({
  children,
  heading,
  height
}: {
  children: any
  heading: string
  height?: string | any
}) => {
  return (
    <div
      style={{
        borderRadius: 10,
        border: '1px solid #d3d3d3',
        padding: 0,
        marginBottom: 20
      }}
    >
      <div
        style={{
          backgroundColor: '#FFD580',
          WebkitPrintColorAdjust: 'exact',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          height: 50,
          padding: 10
        }}
      >
        <h4>{heading}</h4>
      </div>
      <div
        style={{
          padding: 10,
          minHeight: height || '5vh'
        }}
      >
        {children}
      </div>
    </div>
  )
}

const ViewDocument = ({
  isOpen,
  toggle,
  title,
  src
}: {
  isOpen: any
  toggle: any
  title: any
  src: any
}) => {
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} size="xl">
      <ModalTitle title={title} />
      <ModalBody>
        <iframe
          title="Document Preview"
          src={src}
          width="100%"
          height="700px"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={() => toggle()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default function DocumentationTree() {
  const [select, setSelect]: any = useState()
  const [document, setDocument]: any = useState()

  const onNameClick = ({ defaultOnClick, nodeData }: any) => {
    defaultOnClick()
    setSelect(nodeData)
  }

  const FolderIcon = ({ onClick: defaultOnClick }: any) => {
    return <PiFolderSimpleFill onClick={defaultOnClick} color="orange" />
  }

  const FolderOpenIcon = ({ onClick: defaultOnClick }: any) => {
    return <PiFolderNotchOpenFill onClick={defaultOnClick} color="orange" />
  }

  const FileIcon = ({ onClick: defaultOnClick }: any) => {
    return <BsFileEarmarkTextFill onClick={defaultOnClick} color="lightblue" />
  }

  const customIdentPixels = 50
  const iconComponents = {
    FolderIcon,
    FolderOpenIcon,
    FileIcon
  }

  return (
    <ProtectedLayout onBack="/cmms/manual" title="Manuals">
      <Row style={{ margin: 15, fontSize: 16 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%', marginRight: 10 }}>
            <Box heading="Documentation" height="76vh">
              <FolderTree
                onNameClick={onNameClick}
                data={documents}
                iconComponents={iconComponents}
                indentPixels={customIdentPixels}
                showCheckbox={false}
                initOpenStatus="closed"
                readOnly
              />
            </Box>
          </div>
          <div style={{ width: '50%', marginLeft: 20 }}>
            <Box heading="View Manual" height="76vh">
              <iframe
                title="Document Preview"
                src={require('./../../../src/Maint Strategy.pdf')}
                width="100%"
                height="800px"
              />
            </Box>
          </div>
        </div>
      </Row>
      <ViewDocument
        isOpen={!!document}
        toggle={() => setDocument(!document)}
        src={document}
        title={'Document'}
      />
    </ProtectedLayout>
  )
}
