/* eslint-disable jsx-a11y/anchor-is-valid */
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { FaRegUser } from "react-icons/fa";
import { GoGear } from "react-icons/go";
import { HiHome } from "react-icons/hi2";
import { MdBorderColor, MdOutlineSpaceDashboard } from "react-icons/md";
import { Row, Col } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

function isID(str: any) {
  const idPattern = /^[0-9a-fA-F]{24}$/;
  return idPattern.test(str);
}

const crumbs: any = {
  Cmms: "CMMS",
  Home: <HiHome />,
  Pm: "PM",
  heCard: "SHE CARD",
  Rcm: "RCM",
  Floc: "FLOC",
  CmmsDashboard: "CMMS Dashboard",
  incidentManagement: "Incident Managment",
  Stage1: "Stage 1",
  Stage2: "Stage 2",
  Stage3: "Stage 3",
  Stage4: "Stage 4",
  Stage5: "Stage 5",
  Stage6: "Stage 6",
  Stage7: "Stage 7",
  Stage8: "Stage 8",
  StageA: "Stage A",
  SheCard: "TSHE Card",
  AshokLeyland: "Ashok Leyland",
  Mms: "Gi - MMS",
  Incidentreporting: "Incident Reporting",
  Tshe: "TSHE Card",
  Firs: "FIRS",
  TrainingData: "Training Data",
  Peptalkawareness: "Pep Talking Awareness",
  Traininglist: "Training List",
  Warninglist: "Warning List",
  UpdateRunningHours: "Update Running Hours",
  SupplyChain: "Supply Chain",
  Itemmaster: "Item Master",
};

export default function Breadcrumbs() {
  const breadcrumbs = useReactRouterBreadcrumbs();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const service = pathname?.split("/")?.[1];

  return (
    <>
      <Row style={{ margin: 0, borderTop: "2px solid #000" }}>
        <Col
          xs="12"
          style={{
            paddingTop: 12,
            paddingBottom: 10,
            display: "flex",
            width: "100%",
            borderBottom: "2px solid #000",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div className="breadcrumb flat">
              {breadcrumbs.map(({ match, breadcrumb }: any) => (
                <a
                  className=""
                  key={match.pathname}
                  onClick={() => navigate(match?.pathname)}
                >
                  {crumbs[breadcrumb?.props?.children] ? (
                    crumbs[breadcrumb?.props?.children]
                  ) : isID(breadcrumb?.props?.children) ? (
                    <MdBorderColor />
                  ) : (
                    breadcrumb
                  )}
                </a>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
                cursor: "pointer",
                fontWeight: 700,
              }}
            >
              <div
                onClick={() =>
                  service === "eptw"
                    ? navigate("/eptw/dashboard/main")
                    : navigate("/cmmsDashboard")
                }
              >
                <MdOutlineSpaceDashboard
                  size={25}
                  onClick={() => navigate("/cmmsDashboard")}
                />
                Dashboard
              </div>
              <>
                <FaRegUser size={25} onClick={() => navigate("/myProfile")} />
                My Profile
              </>
              <>
                <GoGear size={25} onClick={() => navigate("/tickets")} />
                Tickets
              </>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
