export const endpoint = process.env.REACT_APP_NODE_API

export const getAllCMMSWO: any = `${endpoint}/api/v1/permits`
export const createCMMSWO: any = `${endpoint}/api/v1/permits`
export const createCMCloseOut: any = `${endpoint}/api/v1/correctiveMaintenance/closeOut`
export const getCloseOutPermit: any = `${endpoint}/api/v1/correctiveMaintenance/closeOut`
export const getPMList: any = `${endpoint}/api/v1/protectiveMaintenanceList`
export const protectiveMaintenancePermit: any = `${endpoint}/api/v1/protectiveMaintenancePermit`
export const stock: any = `${endpoint}/api/v1/stock`
export const incidentReport: any = `${endpoint}/api/v1/incidentReport`
export const stage2API: any = `${endpoint}/api/v1/stage2`
export const stage3API: any = `${endpoint}/api/v1/stage3`
export const stage4API: any = `${endpoint}/api/v1/stage4`
export const tagsAPI: any = `${endpoint}/api/v1/tagNumbers`
export const adminCMPermissions: any = `${endpoint}/api/v1/admin/cm`
export const adminPMPermissions: any = `${endpoint}/api/v1/admin/pm`
export const adminePermitPermissions: any = `${endpoint}/api/v1/admin/epermit`
export const pmTags: any = `${endpoint}/api/v1/pmFilters`
export const riskAssessmentAPI: any = `${endpoint}/api/v1/riskAssesments`
export const stockFilters: any = `${endpoint}/api/v1/stockFilters`
export const permitFilters: any = `${endpoint}/api/v1/permitFilters`
export const adminFirsPermissions: any = `${endpoint}/api/v1/admin/firs`
export const epermit: any = `${endpoint}/api/v1/epermit`
export const newPermitEndpoint: any = `${endpoint}/api/v1/newPermit`
export const revalidateEndpoint: any = `${endpoint}/api/v1/revalidate`
export const deIsolationCloseEndPoint: any = `${endpoint}/api/v1/deIsolationAndClose`
export const ltiEndPoint: any = `${endpoint}/api/v1/lti`
export const cioEndpoint: any = `${endpoint}/api/v1/cio`
export const woiEndpoint: any = `${endpoint}/api/v1/woi`
export const cwoiEndpoint: any = `${endpoint}/api/v1/cwoi`
export const epermitCloseEndPoint: any = `${endpoint}/api/v1/epermitCloseOut`
export const countsEndpoint: any = `${endpoint}/api/v1/counts`
export const liveIsolationsLTI: any = `${endpoint}/api/v1/liveIsolationsLTI`
export const qrlocalEndpoint: any = `${endpoint}/permit`
export const auditEndpoint: any = `${endpoint}/api/v1/audit`
export const informationCardEndpoint: any = `${endpoint}/api/v1/informationCard`
export const usersEndpoint: any = `${endpoint}/api/v1/users`
export const analyticsEndpoint: any = `${endpoint}/api/v1/analytics`
export const localqrEndpoint: any = `${endpoint}/permit`
export const getAttachment: any = `${endpoint}/public/cm/attachments`
export const invoiceAPI: any = `${endpoint}/public/joc/attachments`
export const getTickets: any = `${endpoint}/public/tickets`
export const functionHierarchy: any = `${endpoint}/api/v1/functionHierarchy`
export const equipmentAPI: any = `${endpoint}/api/v1/equipment`
export const manualAPI: any = `${endpoint}/api/v1/manual`
export const workOrderAPI: any = `${endpoint}/api/v1/workOrder`
export const financeAPI: any = `${endpoint}/api/v1/finance`
export const pmAPI: any = `${endpoint}/api/v1/pm`
export const systemsApi: any = `${endpoint}/api/v1/systems`
export const thermalImageApi: any = `${endpoint}/api/v1/thermalImage`
export const mocApi: any = `${endpoint}/api/v1/moc`
export const ticketApi: any = `${endpoint}/api/v1/ticket`
export const alEquipmentApi: any = `${endpoint}/api/v1/alEquipment`
export const qrEndpoint: any = `https://dev-cmms-api-hoec.e73.ai/permit`
export const qrUrl = 'https://hoec.e73.ai'
