import { Field } from 'react-final-form'
import { Col, Input } from 'reactstrap'
import CustomLabel from './CustomLabel'
// import DatePicker from 'react-datepicker';
// import {DatePicker} from 'react-datepicker'

// import "react-datepicker/dist/react-datepicker.css";

const textArea = {
  border: '1px solid #CCC',
  borderRadius: 5,
  width: '100%',
  height: 40,
  paddingLeft: 5,
  backgroundColor: '#DCDCDC'
}

type InputType = {
  label?: string
  name?: any
  isDisabled?: boolean
  occupy?: number
  startDate?: any
  time?: boolean
}

export function getToday() {
  const today = new Date()
  const year = today.getFullYear()
  let month: any = today.getMonth() + 1
  let day: any = today.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  return `${year}-${month}-${day}`
}

export default function LabeledDateTimeInput({
  label,
  name,
  isDisabled = false,
  occupy = 6,
  startDate,
  time = false
}: InputType) {
  const type = time ? 'datetime-local' : 'date'

  return (
    <Col md={occupy} key={`${name}*${label}`}>
      {label && <CustomLabel label={label} />}
      <Field name={name} style={textArea}>
        {({ input, meta }) => (
          <div key={`${name}^${label}`}>
            <input
              {...input}
              type={type}
              style={textArea}
              disabled={isDisabled}
              value={input.value}
              step="1"
              min={getToday()}
            />
            {/* <input type="date" max={startDate} step="1" style={textArea}></input> */}
            {meta.error && meta.touched && (
              <span style={{ color: 'red', fontWeight: 500 }}>
                {meta.error}
              </span>
            )}
          </div>
        )}
      </Field>
    </Col>
  )
}
