import { Route, Routes } from "react-router-dom";
import AdminDashboard from "../pages/admin/AdminDashboard";
import AssignGroups from "../pages/admin/AssignGroups";
import Vendors from "../pages/inventory/Vendors";
import TravelActions from "../pages/travel/TravelActions";

export default function TravelRoutes() {
  return (
    <Routes>
      <Route path="/" element={<TravelActions />} />
      {/* <Route path="/" element={<AdminDashboard />} />
      <Route path="/groups" element={<AssignGroups />} />
      <Route path="/vendors" element={<Vendors />} /> */}
    </Routes>
  );
}
