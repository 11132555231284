import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Container, Button } from 'reactstrap'
import { UserContext } from '../App'
import Loader from '../components/Loader'
import Spacer from '../components/Spacer'
import theme from './../components/constants/defaultTheme.json'
import CircleMenu from '../SVGs/CircleMenu'

const JOB_TITLES = {
  VENDOR: 'VENDOR',
  FF: 'FRIEGHT FORWARDER',
  CUSTOMS: 'customs',
  AUDIT: 'audit'
}

export const ServiceCard = ({ service, onClick }: any) => {
  const { userData } = useContext(UserContext)
  const navigate = useNavigate()

  if (userData.jobTitle === JOB_TITLES.VENDOR) {
    navigate('/vendor/dashboard')
  }

  if (userData.jobTitle === JOB_TITLES.CUSTOMS) {
    navigate('/customs/dashboard')
  }

  if (userData.jobTitle === JOB_TITLES.FF) {
    navigate('/frieghtForwarder/dashboard')
  }

  if (userData.jobTitle === JOB_TITLES.AUDIT) {
    navigate('/audit/dashboard')
  }

  if (
    !userData ||
    userData?.jobTitle === JOB_TITLES?.VENDOR ||
    userData?.jobTitle === JOB_TITLES?.FF ||
    userData?.jobTitle === JOB_TITLES?.CUSTOMS ||
    userData?.jobTitle === JOB_TITLES?.AUDIT
  ) {
    return <Loader />
  }

  const cardStyles: any = {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 25,
    flexDirection: 'column',
    paddingTop: 50,
    paddingBottom: 50,
    borderRadius: 3,
    // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
    boxShadow: '28px 30px 6px 10px rgba(207,205,207,0.49)',
    minHeight: '65vh'
  }

  return (
    <React.Fragment key={service?.title}>
      <Col md="12" style={cardStyles} onClick={() => onClick()}>
        <React.Fragment>
          <p
            style={{
              fontFamily: 'Copperplate',
              fontSize: 24,
              textAlign: 'center',
              color: theme.colors.blue,
              minHeight: '10vh'
            }}
          >
            <b>{service?.title?.toUpperCase()}</b>
          </p>
          <div
            style={{
              height: 280,
              width: 280,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: 'linear-gradient(to right, #ff512f, #f09819)',
              borderRadius: 10
            }}
          >
            <div
              style={{
                background: '#fff',
                borderRadius: 10,
                height: 265,
                width: 265,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                src={service?.img}
                alt={service?.description}
                height="200vh"
                style={{
                  height: 250,
                  width: 250,
                  objectFit: 'contain',
                  backgroundColor: '#fff',
                  borderRadius: 10
                }}
              />
            </div>
          </div>
          <Spacer height={20} />
          <div
            onClick={() => navigate(service.redirect)}
            style={{
              background: '#fff',
              borderRadius: '50%',
              height: 150,
              width: 150,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px'
              boxShadow: ' 0px 2px 6px 4px rgba(13,13,13,0.18)'
            }}
          >
            <div
              style={{
                height: 130,
                width: 130,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: 'linear-gradient(to right, #ff512f, #f09819)',
                borderRadius: '50%'
              }}
            >
              <div
                style={{
                  background: '#fff',
                  borderRadius: '50%',
                  height: 100,
                  width: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <p
                  style={{
                    fontFamily: 'sans-serif',
                    margin: 0,
                    color: theme.colors.blue,
                    fontWeight: 900,
                    fontSize: 25
                  }}
                >
                  START
                </p>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Col>
    </React.Fragment>
  )
}

export function ViewServices({ services }: { services: any }) {
  const navigate = useNavigate()
  return (
    <Row
      style={{
        justifyContent: 'space-between',
        paddingTop: 30,
        display: 'flex',
        height: '100vh'
      }}
    >
      <Col xs="1" />
      <Col style={{ marginLeft: 30, marginRight: '0.7rem' }}>
        <ServiceCard
          service={services?.[0]}
          onClick={() => navigate(services?.[0]?.redirect)}
        />
      </Col>
      <Col style={{ marginRight: '0.7rem' }}>
        <ServiceCard
          service={services?.[1]}
          onClick={() => navigate(services?.[1]?.redirect)}
        />
      </Col>
      {services?.[2] && (
        <Col style={{ marginRight: 30 }}>
          <ServiceCard
            service={services?.[2]}
            onClick={() => navigate(services?.[2]?.redirect)}
          />
        </Col>
      )}
      {services?.[3] && (
        <Col style={{ marginRight: 30 }}>
          <ServiceCard
            service={services?.[3]}
            onClick={() => navigate(services?.[3]?.redirect)}
          />
        </Col>
      )}
      <Col xs="1" />
    </Row>
  )
}

export default function Services() {
  const navigate = useNavigate()
  return (
    <>
      <Container fluid style={{ minHeight: '60vh' }}>
        <Row>
          <Spacer height={10} />
          <Col
            xs="12"
            style={{
              // display: 'flex',
              // justifyContent: 'space-between',
              // backgroundColor: 'white',
              // padding: '10px 0 10px 0',
              // alignItems: 'center',
              borderBottom: '2px solid #000'
            }}
          >
            <Row
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Col xs="4"></Col>
              <Col xs="4" style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={require('./../../src/images/e73_logo.jpeg')}
                  alt="HOEC Logo"
                  height={120}
                />
              </Col>
              <Col
                xs="4"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  color="warning"
                  onClick={() => navigate('/ashokLeyland')}
                >
                  View Ashok Leyland Portal
                </Button>
              </Col>
              {/* <div style={{ width: 80 }} />
              <div>
                
              </div>
              <div>
                
              </div> */}
            </Row>
          </Col>
        </Row>
        {/* <ViewServices services={services} /> */}
        <div
          style={{
            // height: '60vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircleMenu />
        </div>
      </Container>
    </>
  )
}
