import { Row, Col } from 'reactstrap'
import { IoCaretBack } from 'react-icons/io5'
import { useContext } from 'react'
import { useMsal } from '@azure/msal-react'

import DropdownHeader from './Dropdown'
import { UserContext } from '../../App'

import './../css/breadcrumbs.css'

export default function DashboardHeader({
  onBack = () => null,
  title,
  logo
}: any) {
  const { instance } = useMsal()
  const { userData }: any = useContext(UserContext)

  const handleLogout = async () => {
    await instance.logoutRedirect()
  }

  return (
    <>
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: 5
        }}
      >
        <Col
          md="2"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <img
            src={logo || require('./../../images/Icons/Clamp.png')}
            alt="Brand Logo"
            height={logo ? 50 : 50}
            style={{ marginLeft: logo ? 10 : 10, mixBlendMode: 'darken' }}
          />
          <div
            onClick={() => onBack()}
            style={{
              backgroundColor: '#3A3C1C',
              paddingLeft: 15,
              paddingRight: 15,
              display: 'flex',
              alignItems: 'center',
              borderRadius: 10,
              height: 60,
              marginLeft: 15
            }}
          >
            <IoCaretBack size={20} color="#FFF" onClick={() => onBack()} />
            <p style={{ marginBottom: 0, color: '#FFF', fontWeight: 700 }}>
              Back
            </p>
          </div>
        </Col>
        <Col md="8">
          <h3 style={{ textAlign: 'center' }}>
            <b>{title?.toUpperCase()}</b>
          </h3>
        </Col>
        <Col md="2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DropdownHeader
            logout={handleLogout}
            currentUser={userData?.jobTitle || 'Job Title'}
            userName={userData?.givenName || 'User Name'}
          />
        </Col>
      </Row>
    </>
  )
}
