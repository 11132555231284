import { Row } from 'reactstrap'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'

export default function RcmKpi() {
  return (
    <ProtectedLayout onBack="/cmms/rcm">
      <Row style={{ margin: 20 }}>
        <img src={require('./../../images/rcmKpi.jpeg')} alt="rcm kpi" />
      </Row>
    </ProtectedLayout>
  )
}
