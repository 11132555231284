import { useContext } from 'react'
import ProtectedLayout from '../components/Layout/ProtectedLayout'
import Spacer from '../components/Spacer'
import { renderData } from './workManagement/PmTree'
import { UserContext } from '../App'
import { getReadableTime } from './workManagement/WorkOrderDetails'
import { Button } from 'reactstrap'

export default function MyProfile() {
  const { userData } = useContext(UserContext)

  const userDetails: any = {
    Name: userData?.givenName,
    'Last Name': userData?.surname,
    'Employee Id': userData?.employeeId,
    Designation: userData?.jobTitle,
    Department: userData?.department,
    'Email Id': userData?.userPrincipalName,
    'Date of Joining': getReadableTime(userData?.createdDateTime),
    'User Type': userData?.userType,
    'Mail Nickname': userData?.mailNickname,
    'Account Status': (
      <p style={{ marginBottom: 0, color: 'green' }}>
        {userData?.accountEnabled ? 'Active' : 'Disabled'}
      </p>
    )
  }

  const maintenanceItems = [
    'WO Overdue ( MECH) - 14 days (P1,P2,P3)',
    'WO Overdue ( MECH)- PY1 -14 days',
    'WO Overdue ( MECH)- PY2- 14 days	',
    'WO Overdue ( MECH)- PY3- - 14 days	',
    'WO Overdue (MECH)- 28 days -P1	',
    'WO Overdue (MECH)- 28 days -P2	',
    'WO Overdue (MECH)- 28 days -P3'
  ]

  const procurementItems = [
    'Trip Urgent Requestion ( Mech)',
    'Urgent Requestion (Mech)',
    'Approved Work Request (Mech',
    'Approved PO (Mech)	Approved RFQ (Mech)'
  ]

  const ColorBox = ({ color }: { color: any }) => (
    <div
      style={{
        backgroundColor: color,
        border: '1px solid #d3d3d3',
        marginRight: '1vh',
        height: '5vh',
        width: '10vh',
        borderRadius: 5
      }}
    />
  )

  return (
    <div style={{ height: '100vh', width: '100vw', overflow: 'scroll' }}>
      <ProtectedLayout onBack="/">
        <div style={{ padding: '3vh' }}>
          <div style={{ height: 'inherit', width: 'inherit' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end'
              }}
            >
              <h1>My Profile</h1>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h6>Select Language:</h6>
                <img
                  src={require('./../images/Icons/brazil flag.webp')}
                  style={{
                    height: '3vh',
                    width: '4vh',
                    marginRight: 5,
                    marginLeft: 5,
                    paddingBottom: 3
                  }}
                  alt="flag"
                />
                <img
                  src={require('./../images/british flag.png')}
                  style={{
                    height: '3vh',
                    width: '4vh',
                    paddingBottom: 3,
                    objectFit: 'cover',
                    borderBottom: '3px solid green'
                  }}
                  alt="flag"
                />
              </div>
            </div>
            <Spacer height={'3vh'} />
            <div
              style={{
                height: '63vh',
                width: 'inherit',
                display: 'flex',
                backgroundColor: 'rgba(211,211,211,0.3)',
                borderRadius: 10,
                padding: '3vh'
              }}
            >
              <div
                style={{
                  width: '30%',
                  backgroundColor: '#fff',
                  borderRadius: 10,
                  boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)'
                }}
              >
                <div style={{ padding: '3vh' }}>
                  <img
                    alt="Profile Pictur"
                    src={require('./../images/sample pic.webp')}
                    style={{
                      height: '20vh',
                      width: '-webkit-fill-available',
                      border: '1px solid #d3d3d3',
                      objectFit: 'cover',
                      borderRadius: 5,
                      boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px'
                    }}
                  />
                </div>
                <div style={{ padding: '0vh 3vh 3vh 3vh' }}>
                  {Object.keys(userDetails)?.map((key: any) =>
                    renderData(key, userDetails[key])
                  )}
                  <div
                    style={{
                      display: 'flex',
                      paddingTop: '3vh',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Button
                      style={{
                        borderRadius: 50,
                        color: '#000',
                        padding: '8px 16px 8px 16px',
                        backgroundImage:
                          'linear-gradient(to right, #ff512f, #f09819)'
                      }}
                    >
                      Edit Details
                    </Button>
                    <Button
                      style={{
                        borderRadius: 50,
                        color: '#000',
                        padding: '8px 16px 8px 16px',
                        backgroundImage:
                          'linear-gradient(to right, #ff512f, #f09819)'
                      }}
                    >
                      Reset Password
                    </Button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginLeft: '3vh',
                  width: '70%'
                }}
              >
                <div
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    padding: '3vh',
                    height: '50%',
                    marginBottom: '3vh',
                    boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)'
                  }}
                >
                  <h2>Setup Preferences</h2>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ width: '50%' }}>
                      <h5>Maintenance</h5>
                      <div style={{ overflowY: 'scroll', height: '90%' }}>
                        {maintenanceItems?.map((i: any) => (
                          <p style={{ marginBottom: 0 }} key={i}>
                            {i}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div style={{ width: '50%', marginLeft: '2vh' }}>
                      <h5>Procurement</h5>
                      <div style={{ overflowY: 'scroll', height: '90%' }}>
                        {procurementItems?.map((i: any) => (
                          <p style={{ marginBottom: 0 }} key={i}>
                            {i}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    padding: '3vh',
                    height: '44%',
                    boxShadow: '13px 14px 20px 2px rgba(0,0,0,0.1)'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h2>Select Background Color</h2>
                    <div
                      style={{
                        backgroundColor: '#d3d3d3',
                        marginLeft: '3vh',
                        height: '5vh',
                        width: '10vh',
                        borderRadius: 5
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', paddingTop: '3vh' }}>
                    <ColorBox color="ligtblue" />
                    <ColorBox color="#B0DDF2" />
                    <ColorBox color="#FFEDBB" />
                    <ColorBox color="#FFC6C6" />
                    <ColorBox color="#E8FFC6" />
                    <ColorBox color="#FFCDF7" />
                    <ColorBox color="#FFE7C7" />
                    <ColorBox color="#E1F8DC" />
                    <ColorBox color="#CAF1DE" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProtectedLayout>
    </div>
  )
}
