import { Col, Row } from "reactstrap";
import ProtectedLayout from "../../components/Layout/ProtectedLayout";

export default function TravelActions() {
  return (
    <ProtectedLayout>
      <Row style={{ margin: 15 }}>
        <Col>Sopme</Col>
      </Row>
    </ProtectedLayout>
  );
}
