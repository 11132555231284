import moment from 'moment'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap'

import BoundarySvg from '../../SVGs/boundarySvg'
import PIDModalHeader from '../../SVGs/PIDModalHeader'
import ProcessFlowDiagram from '../../SVGs/ProcessFlowDiagram'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import toNormalCase from '../../components/lib/toNormalCase'
import { capitalizeFirstLetter } from '../../components/lib/capitalizeFirstLetter'
import { getReadableTime } from '../workManagement/WorkOrderDetails'
import { incrementby1 } from '../../components/Forms/JSAForm'
import { Link } from '../workManagement/PmTree'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { Text } from '../../components/RiskAssesmentPreview'
import { workOrderAPI } from '../../components/serverurl'

import './../../../src/index.css'
import FullSvg from '../../SVGs/FullSvg'
import SingleLineDrawing from '../../SVGs/SingleLineDrawing'
import LoopDrawing from '../../SVGs/LoopDrawing'

function getRandomArbitrary(min: any, max: any) {
  return Math.random() * (max - min) + min
}

const renderTitle = (title: string) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      whiteSpace: 'nowrap'
    }}
  >
    <Text>
      <b>{title}</b>
    </Text>
    <Text>:</Text>
  </div>
)

const renderRow = (title: string, content?: any, color = '#fefaa2') => (
  <div>
    <Row style={{ margin: 0 }}>
      <Col
        xs="6"
        style={{
          // backgroundColor: color,
          WebkitPrintColorAdjust: 'exact',
          fontSize: 14
        }}
      >
        {renderTitle(title)}
      </Col>
      <Col xs="6">
        <Text styles={{ fontSize: 14 }}>{content || 'NA'}</Text>
      </Col>
    </Row>
  </div>
)

const historyHeaders = [
  'Number',
  'Description',
  'Equipment No',
  'Equipment Description',
  'System',
  'PM Type',
  'Priority',
  'Status',
  'Completion date',
  'Resp. Discipline',
  'Planned Hours',
  'Resources	Cost',
  'History'
]

const inventoryHeaders = ['Number', 'Tag', 'Status']

const systems = [
  'System 0',
  'System 20',
  'System 21',
  'System 10',
  'System 24',
  'System 25',
  'System 27',
  'System 29',
  'System 40',
  'System 41',
  'System 42',
  'System 43',
  'System 44',
  'System 45',
  'System 50',
  'System 51',
  'System 52',
  'System 53',
  'System 55',
  'System 56'
]

const buttons = [
  // { label: 'Back', url: '/cmms' },
  { label: 'Home', url: '/' },
  { label: 'CMMS', url: '/cmms' },
  { label: 'ePTW', url: '/eptw/dashboard' },
  { label: 'FIRS', url: '/firs' },
  { label: 'RCM', url: '/cmms/rcm' },
  { label: 'SHE CARD', url: '/firs/sheCard' },
  { label: 'FMEA', url: '/cmms/rcm/fmea' },
  { label: 'Equipment List', url: '/cmms/rcm/equipment' },
  { label: 'Inventory List', url: '/warehouse/stockRegister' },
  { label: 'Forward', url: '/cmms/fmea' }
]

export default function CmmsDashboard() {
  const { accounts, instance } = useMsal()

  const fetchWorkRequests = async () => {
    const response = await makeAuthenticatedRequest(
      `${workOrderAPI}?status=todo`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data: fdata }: any = useQuery('workRequests', fetchWorkRequests)

  const workRequests = fdata?.workrequests

  const navigate = useNavigate()
  const headers = [
    'WO Number',
    'Title',
    'Tag Number',
    'Priority',
    'Criticality',
    'Type',
    'Resp. Discipline'
  ]

  const tdata = [
    'number',
    'description',
    'tagId',
    'priority',
    'criticality',
    'workType',
    'department'
  ]

  const rows = []
  for (let i = 0; i < systems.length; i += 4) {
    rows.push(systems.slice(i, i + 4))
  }

  const [system, setSystem]: any = useState('')
  const [modal, setModal]: any = useState(false)
  const [boundary, setBoundary]: any = useState(false)
  const [equipment, setEquipment]: any = useState()
  const [history, setHistory]: any = useState(false)
  const [hData, setHData]: any = useState([])
  const [inventoryData, setInventoryData]: any = useState([])
  const [inventory, setInventory]: any = useState(false)
  const [pid, setPid]: any = useState('pfd')

  const zeroSystems = ['System 0', 'System 53']

  useEffect(() => {
    if (modal) {
      const eq = workRequests?.filter((d: any) => d?.['tagId'] === modal)?.[0]
      const { equipmentData } = eq || {}
      const { activeCm, activePm, cmHistory, pmHistory, activePr, activePo } =
        equipmentData || {}
      console.log(pmHistory)

      setEquipment({
        ...eq,
        ...moreFields,
        'Active WO Count': (
          <span>
            PM : <strong>{activePm?.length}</strong> | CM:
            <strong>{activeCm?.length}</strong> |{' '}
            <Link
              onClick={() => {
                setHData([...activeCm, ...activePm])
                setHistory(true)
              }}
            >
              View
            </Link>
          </span>
        ),
        'History Count': (
          <span>
            PM : <strong>{pmHistory?.length}</strong> | CM:{' '}
            <strong>{cmHistory?.length}</strong> | FIRS : <strong>4</strong>
          </span>
        ),
        'CM History': (
          <Link
            onClick={() => {
              setHData(cmHistory)
              setHistory(true)
            }}
          >
            Click Here
          </Link>
        ),
        'PM History': (
          <span
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            onClick={() => {
              setHData(pmHistory)
              setHistory(true)
            }}
          >
            Click Here
          </span>
        ),
        'Active Request': (
          <span>
            PR : <strong>{activePr?.length}</strong> | PO:{' '}
            <strong>{activePo?.length}</strong>{' '}
            <Link
              onClick={() => {
                setInventoryData([...activePo, ...activePr])
                setInventory(true)
              }}
            >
              Click Here
            </Link>
          </span>
        )
      })
    }
  }, [modal])

  const moreFields: any = {
    'Last CM carried out':
      moment(
        equipment?.equipmentData?.cmHistory?.[
          equipment?.equipmentData?.cmHistory?.length - 1
        ]?.createdAt
      ).format('DD-MM-YYYY') || 'NA',

    'Last PM Carried out':
      equipment?.equipmentData?.pmHistory?.[
        equipment?.equipmentData?.pmHistory?.length - 1
      ]?.createdAt || 'NA',
    'FIRS History': (
      <span
        style={{
          color: 'blue',
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
      >
        Click Here
      </span>
    ),
    'Available Spares': (
      <span
        style={{
          color: 'blue',
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
        onClick={() => navigate('/warehouse/stockRegister')}
      >
        Click Here
      </span>
    ),
    'Running Hours': <strong>1200</strong>
  }

  const formattedData = (key: any, value: any) => {
    const values: any = {
      priority:
        (value === '1 - 14 Days' && 1) ||
        (value === '2 - 30 Days' && 2) ||
        (value === '3 - 90 Days' && 3) ||
        value,
      department: capitalizeFirstLetter(value),
      workType: (value === 'correctiveMaintenance' && 'CM') || value
    }
    return values[key]
  }

  const activeTags = workRequests?.map((w: any) => w?.tagId)

  const pidList: any = {
    pfd: <ProcessFlowDiagram setPid={setPid} />,
    System43: (
      <FullSvg
        setBoundary={setBoundary}
        setModal={setModal}
        activeTags={activeTags}
        setPid={setPid}
      />
    ),
    boundary: (
      <div style={{ margin: 10, border: '2px solid #000' }}>
        <BoundarySvg />
      </div>
    ),
    sld: <SingleLineDrawing />,
    ld: <LoopDrawing />
  }

  console.log({ equipment })

  return (
    <ProtectedLayout onBack="/cmms">
      <Row style={{ margin: 15 }}>
        <div
          style={{ display: 'flex', gap: 2, width: '100%', marginBottom: 10 }}
        >
          <div id="customTable" style={{ width: '50%' }}>
            <div className="custom-table-responsive">
              <Table
                id="customTable"
                bordered
                style={{ height: '20vh', width: '100%', borderColor: '#000' }}
                responsive
              >
                <thead
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    background: 'white'
                  }}
                >
                  <tr style={{ backgroundColor: '#fafad2' }}>
                    <th>S.No</th>
                    {headers?.map((k: any) => <th key={k}>{k}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {workRequests?.map((d: any, index: any) => (
                    <tr
                      key={d?.['WO Number']}
                      style={{
                        backgroundColor: 'red',
                        color: '#fff',
                        fontWeight: 700
                      }}
                    >
                      <td style={{ textAlign: 'center' }}>
                        {incrementby1(index)}
                      </td>
                      {tdata?.map((h: any) => (
                        <td
                          style={{
                            padding: 5,
                            fontSize: 16,
                            textAlign:
                              h === 'priority' || h === 'woStatus'
                                ? 'center'
                                : 'left'
                          }}
                          key={h}
                        >
                          {formattedData(h, d[h])
                            ? formattedData(h, d[h])
                            : d[h]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div id="customTable" style={{ width: '50%' }}>
            <div className="custom-table-responsive">
              {!system ? (
                <Table bordered style={{ borderColor: '#000' }}>
                  <tbody>
                    {rows.map((row, rowIndex) => (
                      <tr key={rowIndex} style={{ borderColor: '#fff' }}>
                        {row.map((s, colIndex) => (
                          <td
                            style={{
                              borderColor: '#fff',
                              height: 10,
                              padding: 0
                            }}
                            onClick={() => setSystem(s)}
                            key={colIndex}
                          >
                            <Button
                              style={{
                                backgroundColor: '#fafad2',
                                color: '#000',
                                width: '-webkit-fill-available',
                                marginRight: 5,
                                marginLeft: 5,
                                marginBottom: 5,
                                border: 0,
                                boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  margin: 5
                                }}
                              >
                                <p style={{ margin: 0 }}></p>
                                <p
                                  style={{
                                    margin: 0,
                                    fontWeight: 700,
                                    fontSize: 18
                                  }}
                                >
                                  {s}
                                </p>
                                <p
                                  style={{
                                    margin: 0,
                                    height: 'auto',
                                    minWidth: 30,
                                    padding: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    backgroundColor: zeroSystems?.includes(s)
                                      ? 'green'
                                      : 'red',
                                    color: '#fff',
                                    marginRight: 5,
                                    borderRadius: 5,
                                    fontWeight: 700
                                  }}
                                >
                                  {s === 'System 43'
                                    ? workRequests?.length
                                    : zeroSystems?.includes(s)
                                    ? 0
                                    : Math.floor(
                                        getRandomArbitrary(rowIndex, colIndex)
                                      ) + 1}
                                </p>
                              </div>
                            </Button>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Table bordered style={{ borderColor: '#000' }}>
                  <tbody>
                    <tr>
                      {[
                        'HP Flare Tip',
                        'LP Flare Tip',
                        'HP Flare KO Drum',
                        'Flare Ignition panel',
                        'LP Ignition panel'
                      ]?.map((p: any) => (
                        <td
                          key={p}
                          style={{
                            height: 10,
                            textAlign: 'center',
                            fontWeight: 700,
                            backgroundColor: '#fafad2',
                            boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px'
                          }}
                        >
                          {p}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
        {pidList[pid]}
        <section>
          <div
            style={{ display: 'flex', gap: 5, marginTop: 15, zIndex: '999' }}
          >
            <Button
              color="warning"
              onClick={() => setPid('pfd')}
              style={{
                backgroundColor: '#fafad2',
                border: 0,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 8,
                paddingBottom: 8,
                width: '10vw',
                boxShadow:
                  'rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset'
              }}
            >
              <b>Back</b>
            </Button>
            {buttons?.map((b: any) => (
              <Button
                color="warning"
                onClick={() => navigate(b?.url)}
                style={{
                  border: 0,
                  backgroundColor: '#fafad2',
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 8,
                  paddingBottom: 8,
                  width: '10vw',
                  // boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px'
                  boxShadow:
                    'rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset'
                }}
              >
                <b>{b?.label}</b>
              </Button>
            ))}
          </div>
        </section>
      </Row>
      {modal && (
        <Modal
          id="customModal"
          isOpen={modal}
          size="sm"
          style={{
            borderRadius: '20%',
            width: '908px',
            backgroundColor: 'transparent'
          }}
        >
          <ModalHeader
            className="custom-modal"
            style={{
              padding: 0,
              borderRadius: '20%',
              margin: 0,
              background: 'transparent',
              marginLeft: -4
            }}
          >
            <PIDModalHeader />
          </ModalHeader>
          <ModalBody>
            <div>
              {equipment && (
                <>
                  <h4
                    style={{
                      marginBottom: 10,
                      textAlign: 'center',
                      fontWeight: 700
                    }}
                  >
                    {equipment?.['tagId']}
                  </h4>
                  {[
                    ...tdata,
                    'jobCode',
                    ...Object.keys(moreFields),
                    'CM History',
                    'PM History',
                    'History Count',
                    'Active WO Count',
                    'Active Request'
                  ]?.map(
                    (k: any) =>
                      k !== 'tagId' &&
                      renderRow(
                        toNormalCase(k),
                        k === 'Priority' ? (
                          <b>{equipment?.[k]}</b>
                        ) : (
                          equipment?.[k]
                        )
                      )
                  )}
                </>
              )}
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button
              color="warning"
              onClick={() => {
                setModal(false)
                // setEquipment({})
              }}
            >
              <strong>Close</strong>
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {history && (
        <Modal isOpen={history} fullscreen>
          <ModalBody>
            <div>
              <Table bordered style={{ borderColor: '#000' }}>
                <thead>
                  <tr style={{ backgroundColor: 'gold' }}>
                    <th>S.No</th>
                    {historyHeaders?.map((h: any) => <th key={h}>{h}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {hData?.map((h: any, index: any) => (
                    <tr key={h?.['number']}>
                      <td>{incrementby1(index)}</td>
                      <td>
                        <Link
                          onClick={() =>
                            navigate(`/cmms/workOrders/todo/${h?._id}`)
                          }
                        >
                          {h?.number}
                        </Link>
                      </td>
                      <td>{h?.description}</td>
                      <td>{h?.tagId}</td>
                      <td>{h?.tagDescription}</td>
                      <td>{h?.tagId?.split('-')?.[0]}</td>
                      <td>{h?.workType}</td>
                      <td>{h?.priority}</td>
                      <td>{toNormalCase(h?.woStatus)}</td>
                      <td>{getReadableTime(h?.closeOutReport?.closedAt)}</td>
                      <td>{toNormalCase(h?.department)}</td>
                      <td>{h?.executionTime}</td>
                      <td>{h?.closeOutReport?.budgetCode}</td>
                      <td>{h?.closeOutReport?.history}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button color="warning" onClick={() => setHistory(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {inventory && (
        <Modal isOpen={inventory} fullscreen>
          <ModalBody>
            <div>
              <Table bordered style={{ borderColor: '#000' }}>
                <thead>
                  <tr style={{ backgroundColor: 'gold' }}>
                    <th>S.No</th>
                    {inventoryHeaders?.map((h: any) => <th key={h}>{h}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {inventoryData?.map((h: any, index: any) => (
                    <tr key={h?.['number']}>
                      <td>{incrementby1(index)}</td>
                      <td>
                        <Link
                          onClick={() => navigate(`/purchase/wpo/${h?._id}`)}
                        >
                          {h?.number}
                        </Link>
                      </td>
                      <td>{equipment?.tagId}</td>
                      <td>{toNormalCase(h?.status)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button color="warning" onClick={() => setInventory(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ProtectedLayout>
  )
}
