import RcmLayout from '../../components/rcm/RcmLayout'
// import SvgSearchHighlight from '../../SVGs/SearchSvg'

export default function RcmDashboard() {
  return (
    <RcmLayout
      onBack={'/cmms'}
      title={'RCM'}
      tabHeader={<h4 style={{ margin: 0 }}>Dashboard</h4>}
    >
      <p></p>
      {/* <SvgSearchHighlight /> */}
    </RcmLayout>
  )
}
