import { Col, Row } from 'reactstrap'
import { useContext } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'

import { countsEndpoint } from '../../components/serverurl'
import { IconCard } from '../../components/Dashboard/IconCard'
import { makeAuthenticatedRequest } from '../../apiRequests'
import { PermissionsContext, UserContext } from '../../App'
import ProtectedLayout from '../../components/Layout/ProtectedLayout'
import toNormalCase from '../../components/lib/toNormalCase'
import { Box } from '../workManagement/PmTree'

export default function ObservationCardActions() {
  const { permissions } = useContext(PermissionsContext)
  const { accounts, instance } = useMsal()
  const { userData } = useContext(UserContext)
  const userlocation = toNormalCase(permissions?.location)

  const permitsCountByflow = async () => {
    const response = await makeAuthenticatedRequest(
      `${countsEndpoint}/ic?department=${userData?.department}&location=${userlocation}`,
      'GET',
      null,
      accounts,
      instance
    )

    return response
  }

  const { data }: any = useQuery('cioCount', permitsCountByflow)

  const counts = data?.counts

  const icons = [
    {
      name: 'CREATE',
      src: require('./../../images/Icons/She Create.png'),
      nav: '/firs/sheCard/create',
      level: 1
    },
    {
      name: 'DRAFT',
      src: require('./../../images/Icons/Draft_Card.png'),
      nav: '/firs/sheCard/draft',
      level: 1,
      count: counts?.draft
    },
    {
      name: 'PENDING FOR ACTION (HSE)',
      src: require('./../../images/Icons/She Created.png'),
      nav: '/firs/sheCard/created',
      count: counts?.created,
      level: 3
    },
    {
      src: require('./../../images/Icons/SheInprogress.png'),
      name: 'Corrective Action In Progress',
      nav: '/firs/sheCard/inProgress',
      count: counts?.icProgress,
      level: 3
    },
    {
      src: require('./../../images/Icons/She Recommended for Award.png'),
      name: 'Recommended for Award (IM)',
      nav: '/firs/sheCard/recommendAward',
      count: counts?.recomendAward,
      level: 3
    },
    {
      src: require('./../../images/Icons/She Award Winners.png'),
      name: 'Award Winners',
      nav: '/firs/sheCard/awarded',
      count: counts?.awarded,
      level: 3
    },
    {
      src: require('./../../images/Icons/WARNING_LIST.png'),
      name: 'Warning List',
      nav: '/firs/sheCard/warninglist',
      count: 1,
      level: 3
    },
    {
      src: require('./../../images/Icons/She Close (SO).png'),
      name: 'Close (Safety Officer)',
      nav: '/firs/sheCard/closeSafetyOfficer',
      count: counts?.departmentClosed,
      level: 4
    },
    {
      src: require('./../../images/Icons/She Close (OIM).png'),
      name: 'Close (OIM)',
      nav: '/firs/sheCard/closeOim',
      count: counts?.safetyOfficerClosed,
      level: 4
    },
    {
      src: require('./../../images/Icons/She Closed.png'),
      name: 'Closed',
      nav: '/firs/sheCard/closed',
      count: counts?.closed,
      level: 4
    }
  ]

  return (
    <ProtectedLayout title="TSHE Card Dashboard" onBack="/firs">
      <Row style={{ display: 'flex', marginLeft: 5, margin: 15 }}>
        <Row style={{ display: 'flex' }}>
          <Col xs="4" style={{ marginTop: '5vh' }}>
            <Box heading="TSHE Card Request" headingCenter>
              <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                {icons
                  ?.filter((f: any) => f?.level === 1)
                  .map(
                    ({ name, nav, count, canView, src }: any, index: any) => (
                      <IconCard
                        key={`${index}-${name}`}
                        name={name}
                        nav={nav}
                        count={count}
                        src={src}
                      />
                    )
                  )}
              </Col>
            </Box>
          </Col>
          <Col xs="5" style={{ marginTop: '15vh' }}>
            <Box heading="TSHE Card Action" headingCenter>
              <Col
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                {icons
                  ?.filter((f: any) => f?.level === 3)
                  .map(
                    ({ name, nav, count, canView, src }: any, index: any) => (
                      <IconCard
                        key={`${index}-${name}`}
                        name={name}
                        nav={nav}
                        count={count}
                        src={src}
                      />
                    )
                  )}
              </Col>
            </Box>
          </Col>
          <Col xs="3">
            <div
              style={{
                height: '25vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                src={require('./../../images/Icons/tshecard.jpeg')}
                alt="tshe card logo"
                style={{ height: '22vh', width: 'auto' }}
              />
            </div>
            <Box heading="TSHE Card Completed" headingCenter>
              <Col
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                {icons
                  ?.filter((f: any) => f?.level === 4)
                  .map(
                    ({ name, nav, count, canView, src }: any, index: any) => (
                      <IconCard
                        key={`${index}-${name}`}
                        name={name}
                        nav={nav}
                        count={count}
                        src={src}
                      />
                    )
                  )}
              </Col>
            </Box>
          </Col>
        </Row>
      </Row>
    </ProtectedLayout>
  )
}
