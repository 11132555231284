import { Route, Routes } from "react-router-dom";

import FinanceDashboard from "../pages/finance/FinanceDashboard";
import AdminRoutes from "./AdminRoutes";
import PMTree from "../pages/workManagement/PmTree";
import KPIStatistics from "../pages/admin/KPIStatistics";
import InventorySerivces from "../pages/inventory/InventoryServices";
import PlanningServices from "../pages/planning/PlanningServices";
import CorrectiveMaintenanceDashboard from "../pages/workManagement/CorrectiveMaintenanceDashboard";
import WorkMangementRouter from "./WorkMangementRoutes";
import WorkOrderRouter from "./WorkOrdersRouter";
import LookAheadDashboard from "../pages/planning/LookAheadDashboard";
import PmJobsDashoard from "../pages/planning/PMJobsDashboard";
import PMForm from "../pages/workManagement/PMForm";
import PMJobs from "../pages/workManagement/PmJobs";
import PmJobDescription from "../pages/workManagement/PmJobDescription";
import RunningHoursBased from "../pages/pm/RunningHoursBased";
import ViewPreventiveMaintenance from "../pages/pm/ViewPM";
import PmList from "../pages/pm/PmList";
import RcmRoutes from "./RcmRoutes";
import Manuals from "../pages/dashboardPages/Manuals";
import ManualRoutes from "./ManualRoutes";
import ItemMasterRoutes from "./ItemMasterRoutes";
import WareHouseRoutes from "./WarehouseRoutes";

// function WarehouseRoutes() {
//   return (
//     <Routes>
//       <Route path="/" element={<WareHouseRoutes />} />
//     </Routes>
//   );
// }

function SupplyChainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<InventorySerivces />} />
      <Route path="/warehouse/*" element={<WareHouseRoutes />} />
      <Route path="/itemmaster/*" element={<ItemMasterRoutes />} />
    </Routes>
  );
}

export default function CMMSRoutes() {
  return (
    <Routes>
      <Route path="/finance" element={<FinanceDashboard />} />
      <Route path="/admin" element={<AdminRoutes />} />
      <Route path="/admin/*" element={<AdminRoutes />} />
      <Route path="/equipment" element={<PMTree />} />
      <Route path="/rcm/*" element={<RcmRoutes />} />
      <Route path="/manual/*" element={<ManualRoutes />} />
      <Route path="/kpi" element={<KPIStatistics />} />
      <Route path="/manuals" element={<Manuals />} />
      {/* <Route path="/manuals/view" element={<ViewManuals />} /> */}
      <Route path="/planning" element={<PlanningServices />} />
      <Route
        path="/workRequests"
        element={<CorrectiveMaintenanceDashboard />}
      />
      <Route path="/workorders" element={<CorrectiveMaintenanceDashboard />} />

      <Route path="/workRequests/*" element={<WorkMangementRouter />} />
      <Route path="/workOrders/*" element={<WorkOrderRouter />} />

      <Route path="/planning/pm" element={<PmJobsDashoard />} />
      <Route path="/planning/lookahead" element={<LookAheadDashboard />} />
      <Route
        path="/planning/pm/create"
        element={<PMForm onBack={"/cmms/planning/pm"} />}
      />
      <Route path="/planning/pm/jobs" element={<PMJobs />} />
      <Route path="/planning/pm/jobs/:id" element={<PmJobDescription />} />
      <Route
        path="/planning/pm/updateRunningHours"
        element={<RunningHoursBased />}
      />
      <Route
        path="/planning/pm/updateRunningHours/:id"
        element={
          <ViewPreventiveMaintenance
            back={"/cmms/planning/pm/updateRunningHours"}
          />
        }
      />
      <Route path="/planning/pm/list" element={<PmList />} />
      <Route
        path="/planning/pm/list/:id"
        element={<ViewPreventiveMaintenance back={"/cmms/planning/pm/list"} />}
      />
      {/* Supply Chain Routes */}
      <Route path="/supplychain/*" element={<SupplyChainRoutes />} />
    </Routes>
  );
}
