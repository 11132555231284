import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { RiAdminFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import defaultTheme from "./../constants/defaultTheme.json";

function DropdownHeader({
  direction,
  currentUser,
  userName,
  logout,
  ...args
}: any) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const navigate = useNavigate();

  return (
    <div>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        direction={direction}
        style={{ background: "red", borderRadius: 10 }}
      >
        <DropdownToggle
          style={{
            background: "#3A3C1C",
            height: 60,
            paddingRight: 15,
            paddingLeft: 15,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            cursor: "pointer",
          }}
          caret
        >
          <RiAdminFill size={40} color="#FFF" />
          <div style={{ textAlign: "left", marginLeft: 10, marginRight: 10 }}>
            <p style={{ margin: 0 }}>
              <strong>{userName}</strong>
            </p>
            <p style={{ margin: 0, fontSize: 12 }}>{currentUser}</p>
          </div>
        </DropdownToggle>
        <DropdownMenu {...args} style={{ width: "100%", marginTop: 5 }}>
          <DropdownItem
            style={{ height: 40 }}
            onClick={() => navigate("/myDashboard")}
          >
            User Preference
          </DropdownItem>
          <DropdownItem
            onClick={() => navigate("/myprofile")}
            style={{ height: 40 }}
          >
            View Profile
          </DropdownItem>
          <DropdownItem
            onClick={() => navigate("/raiseTicket")}
            style={{ height: 40 }}
          >
            Raise a Ticket
          </DropdownItem>
          <DropdownItem
            onClick={() => navigate("/travel")}
            style={{ height: 40 }}
          >
            Travel Request
          </DropdownItem>
          <DropdownItem onClick={() => logout()} style={{ height: 40 }}>
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default DropdownHeader;
